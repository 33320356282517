<template>
    <v-card class="">
        <!-- <v-card-text class="pa-0">
            <v-img class="" src="@/assets/images/avatars/img-xl.svg" height="120" />
        </v-card-text> -->
        <v-container class="pt-8 pb-10">
            <v-row justify="center">
                <v-col cols="11" class="px-0">
                    <div class="d-flex justify-space-between flex-wrap">
                        <h1 class="primary--text">
                            {{ e1 === 1 ? 'Create a request for colaboration' : 'Send request to recipients' }}
                        </h1>
                    </div>
                    <hr class="hr-l mb-4 mt-2">
                </v-col>

            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-stepper v-model="e1" elevation="0">
                        <v-stepper-header class="px-4 py-0 my-0">
                            <v-stepper-step :complete="e1 > 1" step="1">
                                <span class="text-subtitle-1">First step: Request informations</span>
                            </v-stepper-step>
                            <!-- <v-spacer></v-spacer> -->
                            <v-stepper-step :complete="e1 > 2" step="2" v-if="!isRequestOfExpression">
                                <span class="text-subtitle-1">Second step: Check recipients</span>
                            </v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-card-text class="text-h5 font-weight-bold">Complete request form</v-card-text>
                                <v-card-text>
                                    <v-form>
                                        <p class="text-body-1">Select type of request</p>
                                        <v-progress-linear color="secondary" indeterminate reverse
                                            v-if="this.loading_rfc_list">
                                        </v-progress-linear>
                                        <v-radio-group v-model="rfctype" mandatory row class="pt-0 mt-1" v-else>
                                            <v-radio v-for="(item, i) in rfctypeitem" :value="item.id">
                                                <template v-slot:label>
                                                    <div><span class="primary--text">{{ item.libele }}</span> <span
                                                            class="text-body-2">{{ item.designation }}</span></div>
                                                </template>
                                            </v-radio>
                                        </v-radio-group>
                                        <v-text-field v-model="rfctitle" label="Title" type="text" outlined dense
                                            :hide-details="rfctitleErrors.isvalid"
                                            :error-messages="rfctitleErrors.value" @input="$v.rfctitle.$touch()"
                                            @blur="$v.rfctitle.$touch()" class="mb-2" placeholder="Title">
                                        </v-text-field>
                                        <v-text-field v-model="rfcobject" label="Object" type="text" outlined dense
                                            :hide-details="rfcobjectErrors.isvalid"
                                            :error-messages="rfcobjectErrors.value" @input="$v.rfcobject.$touch()"
                                            @blur="$v.rfcobject.$touch()" class="mb-2"
                                            placeholder="Construction d'un centre hospitalier">
                                        </v-text-field>

                                        <label class="body-1">Proposal content body</label>
                                        <vue-editor v-model="rfccontent" outlined dense
                                            :hide-details="rfccontentErrors.isvalid"
                                            :error-messages="rfccontentErrors.value" class="text-left mb-3" label="Body"
                                            placeholder="Lorem ipsum dolor sit amet. Non magnam asperiores in libero sint">
                                        </vue-editor>
                                    </v-form>
                                </v-card-text>
                                <v-card-text>
                                    <v-row class="d-flex flex-row flex-wrap">
                                        <v-spacer></v-spacer>
                                        <v-col cols="12" md="4" class="">
                                            <v-btn block x-large type="reset" color="error" outlined class="py-6"
                                                @click="resetFirstStape">
                                                Reset
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" md="4" class="">
                                            <v-btn :loading="loading" block x-large color="secondary" class="py-6"
                                                @click="submitCreateNewRequest">
                                                <!-- <v-btn block x-large color="secondary" class="py-6" @click="goToNextStep"> -->
                                                Next
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <select-recipientu :reqkey="this.rfc_keys" :type="'rfc'" class="my-4"
                                    @closeselection="goToPreviousStep"></select-recipientu>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-col>
            </v-row>
        </v-container>
    </v-card>

</template>

<script>
import DialogCheckInfos from '@/views/dashboard/components/DialogCheckInfos.vue'
import { mdiHelpCircleOutline } from '@mdi/js'
import { VueEditor } from "vue2-editor";
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import SelectRecipientu from '@/views/dashboard/requests/components/SelectRecipientu.vue'

export default {
    mixins: [validationMixin],
    validations: {
        rfcobject: { required, minLength: minLength(5) },
        rfctitle: { required },
    },
    components: {
        SelectRecipientu,
        DialogCheckInfos,
        VueEditor
    },
    data() {
        return {
            e1: 1,
            loading: false,
            isRequestOfExpression: false,
            loading_rfc_list: true,
            requestlist: [],
            rfctypeitem: [],
            rfc_keys: -1,
            roottype: ((this.$route.path).substr(0, "/dashc".length).toUpperCase() == "/dashc".toUpperCase()) ? 'company' : 'user'
        }
    },
    watch: {
        $route: {
            handler: function (newRouteValue) {
                let company = "/dashc"
                let professional = "/dashu"
                if ((this.$route.path).substr(0, company.length).toUpperCase() == company.toUpperCase()) {
                    this.roottype = 'dashc'
                }
                if ((this.$route.path).substr(0, professional.length).toUpperCase() == professional.toUpperCase()) {
                    this.roottype = 'dashu'
                }
            },
            deep: true
        }
    },
    beforeMount() {
        this.loading_rfc_list = true
        Drequest.api("lazyloading.requestype")
            .get((response) => {
                switch (response.success) {
                    case true:
                        response.listEntity.forEach((value, index) => {
                            if (value.libele !== 'RFP' && value.libele !== 'REOI' && value.libele !== 'RFC') {
                                this.rfctypeitem.push({
                                    id: value.id,
                                    libele: value.libele,
                                    designation: value.designation
                                })
                            }
                        })
                        this.loading_rfc_list = false
                        break;
                    default:
                        this.$fire({
                            title: " Error occured !",
                            type: "error",
                            text: "Please reloadd the page..!",
                            timer: 3000
                        })
                        break;
                }
            })
            .catch((err) => {
                console.log("err")
                this.loading = false;
            })
        this.loading_rfc_list = false
    },
    computed: {
        rfctitleErrors() {
            const errors = { isvalid: true, value: [] }
            if (!this.$v.rfctitle.$dirty) return errors
            !this.$v.rfctitle.required && errors.value.push('Object is required.') && (errors.isvalid = false)
            return errors
        },
        rfcobjectErrors() {
            const errors = { isvalid: true, value: [] }
            if (!this.$v.rfcobject.$dirty) return errors
            !this.$v.rfcobject.minLength && errors.value.push('Object must be most long') && (errors.isvalid = false)
            !this.$v.rfcobject.required && errors.value.push('Object is required.') && (errors.isvalid = false)
            return errors
        },
        rfccontentErrors() {
            const errors = { isvalid: true, value: [] }
            if (this.rfccontent.length > 0) {
                errors.isvalid = true
            } else {
                errors.value.push('Request content musn\'t be null')
                errors.isvalid = false
            }
            return errors
        },
    },
    methods: {

        goToNextStep() {
            this.e1 = 2
        },
        goToPreviousStep() {
            this.e1 = 1
        },
        resetFirstStape() {
            this.$v.$reset()
            this.rfcobject = ''
            this.rfctitle = ''
            this.rfccontent = ''
        },
        checkValidationForm() {
            if (this.rfcobjectErrors.isvalid && this.rfccontentErrors.isvalid
                && this.rfctitleErrors.isvalid) {
                return true
            }
            else {
                return false
            }
        },

        submitCreateNewRequest() {
            var bodyFormData = {
                "rfc": {
                    "title": this.rfctitle,
                    "owner": this.roottype === 'company' ? 0 : 1,
                    "enterprise.id": (this.roottype === 'company') ? this.$store.getters.getCompany.id : null,
                    "user.id": (this.roottype === 'user') ? this.$store.getters.getUser.id : null,
                    "object": this.rfcobject,
                    "content": this.rfccontent,
                    "requestype.id": this.rfctype
                }
            }

            this.$v.$touch()

            if (this.checkValidationForm()) {
                this.loading = true;
                Drequest.api("create.rfc")
                    .data(bodyFormData)
                    .raw((response) => {
                        if (response.success) {
                            this.loading = false;
                            this.$store.commit("setTids", response.rfc.id);

                            this.$fire({
                                type: "success",
                                text: "Your RFC have been created.",
                                timer: 3000
                            })
                            this.goToNextStep()
                        }
                        else {
                            this.loading = false;

                            this.$fire({
                                type: "error",
                                text: "Uncount error..!",
                                timer: 3000
                            }).then(r => {
                                console.log(r.value);
                            });
                        }
                    })
                    .catch((err) => {
                        this.loading = false;
                        this.$fire({
                            type: "error",
                            text: "Error during connexion to the server..!",
                            timer: 3000
                        }).then(r => {
                            console.log('Error during connexion to the server..!');
                        });

                    })
            }
        },
    },
    setup() {

        const rfctype = ref('')
        const rfcobject = ref('')
        const rfctitle = ref('')
        const rfccontent = ref('')
        const icons = { mdiHelpCircleOutline }

        const recipienttype = ref('')
        return {
            icons,
            recipienttype,
            rfctype, rfcobject, rfctitle, rfccontent,
        }
    },

}
</script>
<style lang="scss" scoped>
@import '@/styles/styles.scss';
</style>